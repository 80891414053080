.container {
	height: 100vh;
	width: 100vw;
	margin: 0;
}

.content {
	max-width: 500px;
	margin: 0 auto;
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: 20px 12px;
}

.center {
	display: flex;
	justify-content: center;
}

.header {
	font-size: 32px;
	font-weight: 700;
	text-align: center;
	margin-bottom: 12px;
}

.valueContainer {
	/* flex: 1 1 auto; */
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	min-width: 300px;
	margin-bottom: 3rem;
}

.title {
	font-size: 24px;
	font-weight: 600;
	text-align: center;
	color: #020306;
	margin: 0 0 1rem 0;
}

.description {
	font-size: 16px;
	font-weight: 500;
	color: #646465;
	margin: 0 auto;
	max-width: 80%;
}

.indicatorGroup {
	display: inline-flex;
	flex-direction: row;
	justify-content: center;
	margin: 2rem;
}

.indicatorIndex {
	height: 8px;
	width: 8px;
	border-radius: 5px;
	background-color: rgba(0, 0, 0, 0.3);
	margin-right: 10px;
	cursor: pointer;
}

.indicatorIndex.currentIndex {
	background-color: #000;
	height: 10px;
	width: 10px;
}

.instruction {
	color: #aeaeb2;
	font-size: 12px;
	margin: 0;
	text-align: center;
}

.instruction .link {
	font-weight: 600;
	color: #007aff;
	cursor: pointer;
}

.imageContainer {
	position: relative;
	/* width: 100%; */
	/* flex: 1 1 auto; */
	height: 400px;
	margin: 0 0 1.4rem 0;
}

.logoSvg {
	max-width: 290px;
	margin: 12px 0;
}

@media only screen and (max-width: 576px) {
	.instruction {
		padding-bottom: 30px;
	}
}

@media only screen and (max-width: 280px) {
	.logoSvg {
			max-width: 250px;
			margin-bottom: 12px;
	}

	.valueContainer {
		min-width: 250px;
	}

	.title {
		font-size: 20px;
	}

	.description {
		font-size: 14px;
	}
}